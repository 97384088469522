@tailwind base;
@tailwind components;
@tailwind utilities;

html.dark ::selection {
  @apply bg-slate-700 text-slate-50;
}

html.light ::selection {
  @apply bg-cyan-300/50;
}

/*
  Global Selectors
*/

body {
  @apply antialiased bg-white dark:bg-[#121212] font-sans;
}

p {
  @apply font-sans;
}

/*
  Animations
*/

.fade-in {
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

/*
  Buttons
*/

button.btn {
  @apply font-medium transition-all duration-150 ease-in-out;
}

button.btn.btn-sm {
  @apply leading-6 px-4 py-2 rounded text-xs;
}

button.btn.btn-md {
  @apply leading-6 px-4 py-2 rounded-md text-sm;
}

button.btn.btn-lg {
  @apply leading-6 px-5 py-3 rounded-md text-sm;
}

button.btn-default {
  @apply bg-slate-200 text-slate-800;
  @apply hover:bg-slate-300;
}

button.btn-primary {
  @apply bg-slate-800 text-white;
  @apply hover:bg-black hover:outline hover:outline-4 hover:outline-white/80;
}

/*
  Content Styling
*/

main h1, main h1 a,
main h2, main h2 a,
main h3, main h3 a,
main h4, main h4 a,
main h5, main h5 a,
main h6, main h6 a {
  @apply mt-0 no-underline scroll-mt-8 transition-all duration-150 ease-in-out;
}

main h1, main h1 a {
  @apply font-medium font-serif text-4xl md:text-6xl mb-0 tracking-tighter;
}

main h1 a {
  @apply text-slate-800 dark:text-white;
}

main h2 {
  @apply font-sans font-semibold text-3xl mb-6 tracking-tight;
}

main h2 a {
  @apply text-slate-800 dark:text-white;
}

main h3 {
  @apply font-sans font-semibold text-xl mb-6 tracking-tight;
}

main h3 a {
  @apply text-slate-700 dark:text-slate-100;
}

main h4 {
  @apply font-sans font-medium text-lg mb-6 tracking-tight;
}

main h4 a {
  @apply text-slate-600 dark:text-slate-200;
}

.article-body {
  @apply text-md leading-8 text-slate-700 dark:text-slate-300;
}

.article-body p {
  @apply my-4;
}

.article-body p:has(img) {
  @apply mb-6;
}

.article-body p > a,
.article-body li > a,
.article-body p > strong a {
  @apply text-rose-600 underline decoration-1 underline-offset-2 decoration-rose-400 dark:text-rose-500 dark:decoration-rose-600;
}

.article-body blockquote {
  @apply border-l-4 border-l-slate-200 dark:border-l-slate-700 italic leading-8 text-xl my-8 pl-6 pr-10 font-medium text-slate-800 dark:text-slate-400;
}

.article-body blockquote p {
  @apply my-0 pt-1;
}

.article-body blockquote cite {
  @apply block leading-6 mt-4 pb-2 not-italic text-xs text-slate-500;
}

.article-body h2 span[role="img"],
.article-body h3 span[role="img"],
.article-body h4 span[role="img"] {
  @apply mr-1;
}

.article-body h2 {
  @apply text-2xl md:text-3xl;
}

.article-body h2:not(:first-child) {
  @apply mt-12;
}

.article-body h3 {
  @apply text-xl md:text-2xl mb-4;
}

.article-body h3:not(:first-child) {
  @apply mt-8;
}

.article-body h4 {
  @apply text-md md:text-lg mb-4;
}

.article-body h4:not(:first-child) {
  @apply mt-6;
}

.article-body hr {
  @apply my-12;
}

.article-body img {
  @apply rounded-sm;
}

.article-body p > img {
  @apply mt-6;
}

.article-body img + small,
.article-body .grid + small {
  @apply block mb-2 mt-4 text-slate-500 text-sm;
}

.article-body .grid img {
  @apply my-0;
}

.article-body .grid p {
  @apply my-0;
}

.article-body .video {
  @apply my-6;
}

.article-body .video > video {
  @apply h-auto w-full;
}

.article-body .video > small {
  @apply block mt-4 text-slate-500 text-sm;
}

.article-body .hash-link:hover::after {
  @apply font-normal text-slate-400 dark:text-slate-600;
  content: ' #';
}

.article-body ol,
.article-body ul {
  @apply mb-6 pl-10;
}

.article-body ol > li,
.article-body ul > li {
  @apply mb-2 pl-2;
}

.article-body ol {
  list-style: numeric;
}

.article-body ul {
  list-style: disc;
}

.article-body strong {
  @apply text-slate-700 dark:text-white;
}

/*
  Article Disclosure Styles
*/

.article-disclosure p {
  @apply leading-7 my-3 text-slate-600 text-sm;
}

.article-disclosure a {
  @apply text-rose-600 underline decoration-1 underline-offset-2 decoration-rose-400 dark:text-rose-500 dark:decoration-rose-600;
}

/*
  Styles
*/

blockquote.basic {
  @apply border-l-4 border-l-cyan-600/25 dark:border-l-slate-700 italic leading-8 text-xl mb-7 mt-4 pl-6 pr-10 font-medium text-slate-800 dark:text-slate-400;
}

blockquote.basic p {
  @apply my-0 py-1 text-lg;
}

blockquote.basic cite {
  @apply block leading-6 mt-2 pb-2 not-italic text-slate-900/60 text-xs;
}

/*
  Calendly Embed
*/

#root .calendly-overlay {
  @apply bg-none;
  /* @apply bg-gradient-to-br from-cyan-500 to-emerald-500; */
}

#root .calendly-overlay .calendly-popup-close {
  @apply hidden !important;
  /* @apply bg-center bg-[length:16px] bg-slate-900 h-12 invert right-7 rounded-full top-7 w-12 transition-all duration-150 ease-in-out; */
}

#root .calendly-overlay .calendly-popup-close:focus,
#root .calendly-overlay .calendly-popup-close:hover {
  @apply invert-0;
}

.ml-embedded .ml-form-embedBody,
.ml-embedded .ml-form-successBody {
  @apply p-0 !important;
}

body .ml-embedded .ml-form-successBody h4,
body .ml-embedded .ml-form-embedBody h4 {
  @apply font-bold font-sans mb-3 text-lg text-left !important;
}

body .ml-embedded .ml-form-successBody p,
body .ml-embedded .ml-form-embedBody p {
  @apply font-sans !important;
}

body .ml-embedded .ml-form-embedBody .ml-form-formContent {
  @apply mb-4 !important;
}

body .ml-embedded .ml-form-embedBody .ml-form-embedContent {
  @apply mb-0 !important;
}

body #mlb2-16261767.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedSubmit button,
body #ml-form-embed.ml-embedded .ml-form-embedBody .ml-form-embedSubmit button {
  @apply h-11 px-4 !important;
}

body #mlb2-16261767.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow {
  @apply mb-2 !important;
}

body #mlb2-16261767.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input {
  @apply h-11 px-4 !important;
}
